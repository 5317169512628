/*
 * Copyright (C) 2020 Graylog, Inc.
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the Server Side Public License, version 1,
 * as published by MongoDB, Inc.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * Server Side Public License for more details.
 *
 * You should have received a copy of the Server Side Public License
 * along with this program. If not, see
 * <http://www.mongodb.com/licensing/server-side-public-license>.
 */
import Reflux from 'reflux';
import URI from 'urijs';
import lodash from 'lodash';

import URLUtils from 'util/URLUtils';
import UserFirewall from 'util/UserFirewall';
import fetch from 'logic/rest/FetchProvider';
import CombinedProvider from 'injection/CombinedProvider';
import InputsStore from "../inputs/InputsStore";

const { FirewallActions } = CombinedProvider.get('Firewall');

const FirewallStore = Reflux.createStore({
  listenables: [FirewallActions],
  sourceUrl: '/events/firewall',
  all: undefined,
  firewall: undefined,
  query: undefined,
  pagination: {
    count: undefined,
    page: undefined,
    pageSize: undefined,
    total: undefined,
    grandTotal: undefined,
  },

  getInitialState() {
    return this.getState();
  },

  propagateChanges() {
    this.trigger(this.getState());
  },

  getState() {
    return {
      all: this.all,
      firewall: this.firewall,
      query: this.query,
      pagination: this.pagination,
    };
  },

  firewallUrl({ segments = [], query = {} }) {
    const uri = new URI(this.sourceUrl);
    const nextSegments = lodash.concat(uri.segment(), segments);

    uri.segmentCoded(nextSegments);
    uri.query(query);

    return URLUtils.qualifyUrl(uri.resource());
  },

  refresh() {
    if (this.all) {
      this.listAll();
    }

    if (this.pagination.page) {
      this.listPaginated({
        query: this.query,
        page: this.pagination.page,
        pageSize: this.pagination.pageSize,
      });
    }
  },

  listAll() {
    const promise = fetch('GET', this.firewallUrl({ query: { per_page: 0 } }));

    promise.then((response) => {
      this.all = response.firewall;
      this.propagateChanges();

      return response;
    });

    FirewallActions.listAll.promise(promise);
  },

  listPaginated({ query = '', page = 1, pageSize = 10 }) {
    const promise = fetch('GET', this.firewallUrl({
      query: {
        query: query,
        page: page,
        per_page: pageSize,
      },
    }));

    promise.then((response) => {
      this.firewall = response.firewall;
      this.query = response.query;

      this.pagination = {
        count: response.count,
        page: response.page,
        pageSize: response.per_page,
        total: response.total,
        grandTotal: response.grand_total,
      };

      this.propagateChanges();

      return response;
    });

    FirewallActions.listPaginated.promise(promise);
  },

  get(firewallId) {
    const promise = fetch('GET', this.firewallUrl({ segments: [firewallId] }));

    promise.catch((error) => {
      if (error.status === 404) {
        UserFirewall.error(`Unable to find Firewall Configuration with id <${firewallId}>, please ensure it wasn't deleted.`,
          'Could not retrieve Firewall Configuration');
      }
    });

    FirewallActions.get.promise(promise);
  },

  getbyInputid(inputId) {
    const promise = fetch('GET', this.firewallUrl({ segments: [inputId] }));

    promise.catch((error) => {
      if (error.status === 404) {
        UserFirewall.error(`Unable to find Firewall Configuration with id <${inputId}>, please ensure it wasn't deleted.`,
          'Could not retrieve Firewall Configuration');
      }
    });

    FirewallActions.getbyInputid.promise(promise);
  },

  create(firewall) {
    const promise = fetch('POST', this.firewallUrl({}), firewall);

    promise.then(
      (response) => {
        UserFirewall.success('Firewall Configuration created successfully', `Firewall"${firewall.title}" was created successfully.`);
        this.refresh();

        return response;
      },
      (error) => {
        if (error.status !== 400 || !error.additional.body || !error.additional.body.failed) {
          UserFirewall.error(`Creating Firewall Configuration "${firewall.inputid}" failed with status: ${error}`,
            'Could not save Firewall Configuration');
        }
      },
    );

    FirewallActions.create.promise(promise);
  },

  update(firewallId, firewall) {
    const promise = fetch('PUT', this.firewallUrl({ segments: [firewallId] }), firewall);

    promise.then(
      (response) => {
        UserFirewall.success('Firewall Configuration updated successfully', `Firewall "${firewall.inputid}" was updated successfully.`);
        this.refresh();

        return response;
      },
      (error) => {
        if (error.status !== 400 || !error.additional.body || !error.additional.body.failed) {
          UserFirewall.error(`Updating Firewall Configuration "${firewall.title}" failed with status: ${error}`,
            'Could not update Firewall Configuration');
        }
      },
    );

    FirewallActions.update.promise(promise);
  },

  delete(firewall) {
    const promise = fetch('DELETE', this.firewallUrl({ segments: [firewall.id] }));

    promise.then(
      () => {
        UserFirewall.success('Firewall Configuration deleted successfully', `Firewall "${firewall.inputid}" was deleted successfully.`);
        this.refresh();
      },
      (error) => {
        UserFirewall.error(`Deleting Firewall Configuration "${firewall.inputid}" failed with status: ${error}`,
          'Could not delete Firewall Configuration');
      },
    );

    FirewallActions.delete.promise(promise);
  },

  test(firewall) {
    const promise = fetch('POST', this.firewallUrl({ segments: ['test'] }), firewall);

    FirewallActions.test.promise(promise);
  },

  testPersisted(firewall) {
    const promise = fetch('POST', this.firewallUrl({ segments: [firewall.id, 'test'] }));

    FirewallActions.testPersisted.promise(promise);
  },
});

FirewallStore.firewallAsMap = (firewallList) => {
  const firewallMap = {};

  firewallList.forEach((firewall) => {
    firewallMap[firewall.id] = firewall;
  });

  return firewallMap;
};
export default FirewallStore;
