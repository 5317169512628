/*
 * Copyright (C) 2020 Graylog, Inc.
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the Server Side Public License, version 1,
 * as published by MongoDB, Inc.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * Server Side Public License for more details.
 *
 * You should have received a copy of the Server Side Public License
 * along with this program. If not, see
 * <http://www.mongodb.com/licensing/server-side-public-license>.
 */
import Reflux from 'reflux';
import URI from 'urijs';
import lodash from 'lodash';

import URLUtils from 'util/URLUtils';
import UserSoarAutomation from 'util/UserSoarAutomation';
import fetch from 'logic/rest/FetchProvider';
import CombinedProvider from 'injection/CombinedProvider';

const { EventSoarAutomationActions } = CombinedProvider.get('EventSoarAutomation');

const EventSoarAutomationStore = Reflux.createStore({
  listenables: [EventSoarAutomationActions],
  sourceUrl: '/events/soar',
  all: undefined,
  actionSoarAutomation: undefined,
  query: undefined,
  pagination: {
    count: undefined,
    page: undefined,
    pageSize: undefined,
    total: undefined,
    grandTotal: undefined,
  },

  getInitialState() {
    return this.getState();
  },

  propagateChanges() {
    this.trigger(this.getState());
  },

  getState() {
    return {
      all: this.all,
      actionSoarAutomation: this.actionSoarAutomation,
      query: this.query,
      pagination: this.pagination,
    };
  },

  eventSoarAutomationUrl({ segments = [], query = {} }) {
    const uri = new URI(this.sourceUrl);
    const nextSegments = lodash.concat(uri.segment(), segments);

    uri.segmentCoded(nextSegments);
    uri.query(query);

    return URLUtils.qualifyUrl(uri.resource());
  },

  refresh() {
    if (this.all) {
      this.listAll();
    }

    if (this.pagination.page) {
      this.listPaginated({
        query: this.query,
        page: this.pagination.page,
        pageSize: this.pagination.pageSize,
      });
    }
  },

  listAll() {
    const promise = fetch('GET', this.eventSoarAutomationUrl({ query: { per_page: 0 } }));

    promise.then((response) => {
      this.all = response.actionSoarAutomation;
      this.propagateChanges();

      return response;
    });

    EventSoarAutomationActions.listAll.promise(promise);
  },

  listPaginated({ query = '', page = 1, pageSize = 10 }) {
    const promise = fetch('GET', this.eventSoarAutomationUrl({
      query: {
        query: query,
        page: page,
        per_page: pageSize,
      },
    }));

    promise.then((response) => {
      this.actionSoarAutomation = response.actionSoarAutomation;
      this.query = response.query;

      this.pagination = {
        count: response.count,
        page: response.page,
        pageSize: response.per_page,
        total: response.total,
        grandTotal: response.grand_total,
      };

      this.propagateChanges();

      return response;
    });

    EventSoarAutomationActions.listPaginated.promise(promise);
  },

  get(actionSoarAutomationId) {
    const promise = fetch('GET', this.eventSoarAutomationUrl({ segments: [actionSoarAutomationId] }));

    promise.catch((error) => {
      if (error.status === 404) {
        UserSoarAutomation.error(`Unable to find Event ActionSoarAutomation with id <${actionSoarAutomationId}>, please ensure it wasn't deleted.`,
          'Could not retrieve Event ActionSoarAutomation');
      }
    });

    EventSoarAutomationActions.get.promise(promise);
  },

  create(actionSoarAutomation) {
    const promise = fetch('POST', this.eventSoarAutomationUrl({}), actionSoarAutomation);

    promise.then(
      (response) => {
        UserSoarAutomation.success('ActionSoarAutomation created successfully', `ActionSoarAutomation "${actionSoarAutomation.title}" was created successfully.`);
        this.refresh();

        return response;
      },
      (error) => {
        if (error.status !== 400 || !error.additional.body || !error.additional.body.failed) {
          UserSoarAutomation.error(`Creating ActionSoarAutomation "${actionSoarAutomation.title}" failed with status: ${error}`,
            'Could not save ActionSoarAutomation');
        }
      },
    );

    EventSoarAutomationActions.create.promise(promise);
  },

  update(actionSoarAutomationId, actionSoarAutomation) {
    const promise = fetch('PUT', this.eventSoarAutomationUrl({ segments: [actionSoarAutomationId] }), actionSoarAutomation);

    promise.then(
      (response) => {
        UserSoarAutomation.success('ActionSoarAutomation updated successfully', `ActionSoarAutomation "${actionSoarAutomation.title}" was updated successfully.`);
        this.refresh();

        return response;
      },
      (error) => {
        if (error.status !== 400 || !error.additional.body || !error.additional.body.failed) {
          UserSoarAutomation.error(`Updating ActionSoarAutomation "${actionSoarAutomation.title}" failed with status: ${error}`,
            'Could not update ActionSoarAutomation');
        }
      },
    );

    EventSoarAutomationActions.update.promise(promise);
  },

  delete(actionSoarAutomation) {
    const promise = fetch('DELETE', this.eventSoarAutomationUrl({ segments: [actionSoarAutomation.id] }));

    promise.then(
      () => {
        UserSoarAutomation.success('ActionSoarAutomation deleted successfully', `Action "${actionSoarAutomation.title}" was deleted successfully.`);
        this.refresh();
      },
      (error) => {
        UserSoarAutomation.error(`Deleting ActionSoarAutomation "${action.title}" failed with status: ${error}`,
          'Could not delete ActionSoarAutomation');
      },
    );

    EventSoarAutomationActions.delete.promise(promise);
  },

  test(actionSoarAutomation) {
    const promise = fetch('POST', this.eventSoarAutomationUrl({ segments: ['test'] }), actionSoarAutomation);

    EventSoarAutomationActions.test.promise(promise);
  },

  testPersisted(actionSoarAutomation) {
    const promise = fetch('POST', this.eventSoarAutomationUrl({ segments: [actionSoarAutomation.id, 'test'] }));

    EventSoarAutomationActions.testPersisted.promise(promise);
  },
});

export default EventSoarAutomationStore;
