/*
 * Copyright (C) 2020 Graylog, Inc.
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the Server Side Public License, version 1,
 * as published by MongoDB, Inc.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * Server Side Public License for more details.
 *
 * You should have received a copy of the Server Side Public License
 * along with this program. If not, see
 * <http://www.mongodb.com/licensing/server-side-public-license>.
 */
import Reflux from 'reflux';
import URI from 'urijs';
import lodash from 'lodash';

import URLUtils from 'util/URLUtils';
import UserIPBlockListing from 'util/UserIPBlockListing';
import fetch from 'logic/rest/FetchProvider';
import CombinedProvider from 'injection/CombinedProvider';

const { IPBlockListingActions } = CombinedProvider.get('IPBlockListing');

const IPBlockListingStore = Reflux.createStore({
  listenables: [IPBlockListingActions],
  sourceUrl: '/events/ipblocklisting',
  all: undefined,
  ipblocklisting: undefined,
  query: undefined,
  pagination: {
    count: undefined,
    page: undefined,
    pageSize: undefined,
    total: undefined,
    grandTotal: undefined,
  },

  getInitialState() {
    return this.getState();
  },

  propagateChanges() {
    this.trigger(this.getState());
  },

  getState() {
    return {
      all: this.all,
      ipblocklisting: this.ipblocklisting,
      query: this.query,
      pagination: this.pagination,
    };
  },

  ipblocklistingUrl({ segments = [], query = {} }) {
    const uri = new URI(this.sourceUrl);
    const nextSegments = lodash.concat(uri.segment(), segments);

    uri.segmentCoded(nextSegments);
    uri.query(query);

    return URLUtils.qualifyUrl(uri.resource());
  },

  refresh() {
    if (this.all) {
      this.listAll();
    }

    if (this.pagination.page) {
      this.listPaginated({
        query: this.query,
        page: this.pagination.page,
        pageSize: this.pagination.pageSize,
      });
    }
  },

  listAll() {
    const promise = fetch('GET', this.ipblocklistingUrl({ query: { per_page: 0 } }));

    promise.then((response) => {
      this.all = response.ipblocklisting;
      this.propagateChanges();

      return response;
    });

    IPBlockListingActions.listAll.promise(promise);
  },

  listPaginated({ query = '', page = 1, pageSize = 10 }) {
    const promise = fetch('GET', this.ipblocklistingUrl({
      query: {
        query: query,
        page: page,
        per_page: pageSize,
      },
    }));

    promise.then((response) => {
      this.ipblocklisting = response.ipblocklisting;
      this.query = response.query;

      this.pagination = {
        count: response.count,
        page: response.page,
        pageSize: response.per_page,
        total: response.total,
        grandTotal: response.grand_total,
      };

      this.propagateChanges();

      return response;
    });

    IPBlockListingActions.listPaginated.promise(promise);
  },

  get(ipblocklistingId) {
    const promise = fetch('GET', this.ipblocklistingUrl({ segments: [ipblocklistingId] }));

    promise.catch((error) => {
      if (error.status === 404) {
        UserIPBlockListing.error(`Unable to find Soar Configuration with id <${ipblocklistingId}>, please ensure it wasn't deleted.`,
          'Could not retrieve Soar Configuration');
      }
    });

    IPBlockListingActions.get.promise(promise);
  },

  create(ipblocklisting) {
    const promise = fetch('POST', this.ipblocklistingUrl({}), ipblocklisting);

    promise.then(
      (response) => {
        UserIPBlockListing.success('Soar configuration created successfully', `Soar configuration "${ipblocklisting.title}" was created successfully.`);
        this.refresh();

        return response;
      },
      (error) => {
        if (error.status !== 400 || !error.additional.body || !error.additional.body.failed) {
          UserIPBlockListing.error(`Creating ISoar configuration "${ipblocklisting.inputid}" failed with status: ${error}`,
            'Could not save Soar configuration ');
        }
      },
    );

    IPBlockListingActions.create.promise(promise);
  },

  update(ipblocklistingId, ipblocklisting) {
    const promise = fetch('PUT', this.ipblocklistingUrl({ segments: [ipblocklistingId] }), ipblocklisting);

    promise.then(
      (response) => {
        UserIPBlockListing.success('Soar configuration updated successfully', `Soar configuration "${ipblocklisting.inputid}" was updated successfully.`);
        this.refresh();

        return response;
      },
      (error) => {
        if (error.status !== 400 || !error.additional.body || !error.additional.body.failed) {
          UserIPBlockListing.error(`Updating Soar configuration"${ipblocklisting.title}" failed with status: ${error}`,
            'Could not update Soar configuration');
        }
      },
    );

    IPBlockListingActions.update.promise(promise);
  },

  delete(ipblocklisting) {
    const promise = fetch('DELETE', this.ipblocklistingUrl({ segments: [ipblocklisting.id] }));

    promise.then(
      () => {
        UserIPBlockListing.success('Soar configuration  deleted successfully', `Soar configuration "${ipblocklisting.inputid}" was deleted successfully.`);
        this.refresh();
      },
      (error) => {
        UserIPBlockListing.error(`Deleting Soar configuration "${ipblocklisting.inputid}" failed with status: ${error}`,
          'Could not delete Soar configuration');
      },
    );

    IPBlockListingActions.delete.promise(promise);
  },

  test(ipblocklisting) {
    const promise = fetch('POST', this.ipblocklistingUrl({ segments: ['test'] }), ipblocklisting);

    IPBlockListingActions.test.promise(promise);
  },

  testPersisted(ipblocklisting) {
    const promise = fetch('POST', this.ipblocklistingUrl({ segments: [ipblocklisting.id, 'test'] }));

    IPBlockListingActions.testPersisted.promise(promise);
  },
});

export default IPBlockListingStore;
