/*
 * Copyright (C) 2020 Graylog, Inc.
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the Server Side Public License, version 1,
 * as published by MongoDB, Inc.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * Server Side Public License for more details.
 *
 * You should have received a copy of the Server Side Public License
 * along with this program. If not, see
 * <http://www.mongodb.com/licensing/server-side-public-license>.
 */
import Reflux from 'reflux';
import URI from 'urijs';
import lodash from 'lodash';

import URLUtils from 'util/URLUtils';
import UserCredentials from 'util/UserCredentials';
import fetch from 'logic/rest/FetchProvider';
import CombinedProvider from 'injection/CombinedProvider';

const { CredentialsActions } = CombinedProvider.get('Credentials');

const CredentialsStore = Reflux.createStore({
  listenables: [CredentialsActions],
  sourceUrl: '/events/credentials',
  all: undefined,
  credentials: undefined,
  query: undefined,
  pagination: {
    count: undefined,
    page: undefined,
    pageSize: undefined,
    total: undefined,
    grandTotal: undefined,
  },

  getInitialState() {
    return this.getState();
  },

  propagateChanges() {
    this.trigger(this.getState());
  },

  getState() {
    return {
      all: this.all,
      credentials: this.credentials,
      query: this.query,
      pagination: this.pagination,
    };
  },

  credentialsUrl({ segments = [], query = {} }) {
    const uri = new URI(this.sourceUrl);
    const nextSegments = lodash.concat(uri.segment(), segments);

    uri.segmentCoded(nextSegments);
    uri.query(query);

    return URLUtils.qualifyUrl(uri.resource());
  },

  refresh() {
    if (this.all) {
      this.listAll();
    }

    if (this.pagination.page) {
      this.listPaginated({
        query: this.query,
        page: this.pagination.page,
        pageSize: this.pagination.pageSize,
      });
    }
  },

  listAll() {
    const promise = fetch('GET', this.credentialsUrl({ query: { per_page: 0 } }));

    promise.then((response) => {
      this.all = response.credentials;
      this.propagateChanges();

      return response;
    });

    CredentialsActions.listAll.promise(promise);
  },

  listPaginated({ query = '', page = 1, pageSize = 10 }) {
    const promise = fetch('GET', this.credentialsUrl({
      query: {
        query: query,
        page: page,
        per_page: pageSize,
      },
    }));

    promise.then((response) => {
      this.credentials = response.credentials;
      this.query = response.query;

      this.pagination = {
        count: response.count,
        page: response.page,
        pageSize: response.per_page,
        total: response.total,
        grandTotal: response.grand_total,
      };

      this.propagateChanges();

      return response;
    });

    CredentialsActions.listPaginated.promise(promise);
  },

  get(credentialsId) {
    const promise = fetch('GET', this.credentialsUrl({ segments: [credentialsId] }));

    promise.catch((error) => {
      if (error.status === 404) {
        UserCredentials.error(`Unable to find Event Credential with id <${credentialsId}>, please ensure it wasn't deleted.`,
          'Could not retrieve Event Credential');
      }
    });

    CredentialsActions.get.promise(promise);
  },

  create(credentials) {
    const promise = fetch('POST', this.credentialsUrl({}), credentials);

    promise.then(
      (response) => {
        UserCredentials.success('Credentials created successfully', `Credentials "${credentials.title}" was created successfully.`);
        this.refresh();

        return response;
      },
      (error) => {
        if (error.status !== 400 || !error.additional.body || !error.additional.body.failed) {
          UserCredentials.error(`Creating Credentials "${credentials.filename}" failed with status: ${error}`,
            'Could not save Credentials');
        }
      },
    );

    CredentialsActions.create.promise(promise);
  },

  update(credentialsId, credentials) {
    const promise = fetch('PUT', this.credentialsUrl({ segments: [credentialsId] }), credentials);

    promise.then(
      (response) => {
        UserCredentials.success('Credentials updated successfully', `Credentials "${credentials.filename}" was updated successfully.`);
        this.refresh();

        return response;
      },
      (error) => {
        if (error.status !== 400 || !error.additional.body || !error.additional.body.failed) {
          UserCredentials.error(`Updating Credentials "${credentials.title}" failed with status: ${error}`,
            'Could not update Credentials');
        }
      },
    );

    CredentialsActions.update.promise(promise);
  },

  delete(credentials) {
    const promise = fetch('DELETE', this.credentialsUrl({ segments: [credentials.id] }));

    promise.then(
      () => {
        UserCredentials.success('Credentials deleted successfully', `Credentials "${credentials.filename}" was deleted successfully.`);
        this.refresh();
      },
      (error) => {
        UserCredentials.error(`Deleting Credentials "${credentials.filename}" failed with status: ${error}`,
          'Could not delete Credentials');
      },
    );

    CredentialsActions.delete.promise(promise);
  },

  test(credentials) {
    const promise = fetch('POST', this.credentialsUrl({ segments: ['test'] }), credentials);

    CredentialsActions.test.promise(promise);
  },

  testPersisted(credentials) {
    const promise = fetch('POST', this.credentialsUrl({ segments: [credentials.id, 'test'] }));

    CredentialsActions.testPersisted.promise(promise);
  },
});

export default CredentialsStore;
