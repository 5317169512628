/*
 * Copyright (C) 2020 Graylog, Inc.
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the Server Side Public License, version 1,
 * as published by MongoDB, Inc.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * Server Side Public License for more details.
 *
 * You should have received a copy of the Server Side Public License
 * along with this program. If not, see
 * <http://www.mongodb.com/licensing/server-side-public-license>.
 */
import Reflux from 'reflux';
import URI from 'urijs';
import lodash from 'lodash';

import URLUtils from 'util/URLUtils';
import UserRiskFactors from 'util/UserRiskFactors';
import fetch from 'logic/rest/FetchProvider';
import CombinedProvider from 'injection/CombinedProvider';

const { RiskFactorsActions } = CombinedProvider.get('RiskFactors');

const RiskFactorsStore = Reflux.createStore({
  listenables: [RiskFactorsActions],
  sourceUrl: '/events/riskfactors',
  all: undefined,
  riskfactors: undefined,
  query: undefined,
  pagination: {
    count: undefined,
    page: undefined,
    pageSize: undefined,
    total: undefined,
    grandTotal: undefined,
  },

  getInitialState() {
    return this.getState();
  },

  propagateChanges() {
    this.trigger(this.getState());
  },

  getState() {
    return {
      all: this.all,
      riskfactors: this.riskfactors,
      query: this.query,
      pagination: this.pagination,
    };
  },

  riskfactorsUrl({ segments = [], query = {} }) {
    const uri = new URI(this.sourceUrl);
    const nextSegments = lodash.concat(uri.segment(), segments);

    uri.segmentCoded(nextSegments);
    uri.query(query);

    return URLUtils.qualifyUrl(uri.resource());
  },

  refresh() {
    if (this.all) {
      this.listAll();
    }

    if (this.pagination.page) {
      this.listPaginated({
        query: this.query,
        page: this.pagination.page,
        pageSize: this.pagination.pageSize,
      });
    }
  },

  listAll() {
    const promise = fetch('GET', this.riskfactorsUrl({ query: { per_page: 0 } }));

    promise.then((response) => {
      this.all = response.riskfactors;
      this.propagateChanges();

      return response;
    });

    RiskFactorsActions.listAll.promise(promise);
  },

  listPaginated({ query = '', page = 1, pageSize = 10 }) {
    const promise = fetch('GET', this.riskfactorsUrl({
      query: {
        query: query,
        page: page,
        per_page: pageSize,
      },
    }));

    promise.then((response) => {
      this.riskfactors = response.riskfactors;
      this.query = response.query;

      this.pagination = {
        count: response.count,
        page: response.page,
        pageSize: response.per_page,
        total: response.total,
        grandTotal: response.grand_total,
      };

      this.propagateChanges();

      return response;
    });

    RiskFactorsActions.listPaginated.promise(promise);
  },

  get(riskfactorsId) {
    const promise = fetch('GET', this.riskfactorsUrl({ segments: [riskfactorsId] }));

    promise.catch((error) => {
      if (error.status === 404) {
        UserRiskFactors.error(`Unable to find Risk Configuration with id <${riskfactorsId}>, please ensure it wasn't deleted.`,
          'Could not retrieve Risk Configuration');
      }
    });

    RiskFactorsActions.get.promise(promise);
  },

  create(riskfactors) {
    const promise = fetch('POST', this.riskfactorsUrl({}), riskfactors);

    promise.then(
      (response) => {
        UserRiskFactors.success('Risk Factors created successfully', `RiskFactors "${riskfactors.title}" was created successfully.`);
        this.refresh();

        return response;
      },
      (error) => {
        if (error.status !== 400 || !error.additional.body || !error.additional.body.failed) {
          UserRiskFactors.error(`Creating RiskFactors "${riskfactors.filename}" failed with status: ${error}`,
            'Could not save RiskFactors');
        }
      },
    );

    RiskFactorsActions.create.promise(promise);
  },

  update(riskfactorsId, riskfactors) {
    const promise = fetch('PUT', this.riskfactorsUrl({ segments: [riskfactorsId] }), riskfactors);

    promise.then(
      (response) => {
        UserRiskFactors.success('RiskFactors updated successfully', `RiskFactors "${riskfactors.filename}" was updated successfully.`);
        this.refresh();

        return response;
      },
      (error) => {
        if (error.status !== 400 || !error.additional.body || !error.additional.body.failed) {
          UserRiskFactors.error(`Updating RiskFactors "${riskfactors.title}" failed with status: ${error}`,
            'Could not update RiskFactors');
        }
      },
    );

    RiskFactorsActions.update.promise(promise);
  },

  delete(riskfactors) {
    const promise = fetch('DELETE', this.riskfactorsUrl({ segments: [riskfactors.id] }));

    promise.then(
      () => {
        UserRiskFactors.success('RiskFactors deleted successfully', `RiskFactors "${riskfactors.filename}" was deleted successfully.`);
        this.refresh();
      },
      (error) => {
        UserRiskFactors.error(`Deleting RiskFactors "${riskfactors.filename}" failed with status: ${error}`,
          'Could not delete RiskFactors');
      },
    );

    RiskFactorsActions.delete.promise(promise);
  },

  test(riskfactors) {
    const promise = fetch('POST', this.riskfactorsUrl({ segments: ['test'] }), riskfactors);

    RiskFactorsActions.test.promise(promise);
  },

  testPersisted(riskfactors) {
    const promise = fetch('POST', this.riskfactorsUrl({ segments: [riskfactors.id, 'test'] }));

    RiskFactorsActions.testPersisted.promise(promise);
  },
});

export default RiskFactorsStore;
