/*
 * Copyright (C) 2020 Graylog, Inc.
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the Server Side Public License, version 1,
 * as published by MongoDB, Inc.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * Server Side Public License for more details.
 *
 * You should have received a copy of the Server Side Public License
 * along with this program. If not, see
 * <http://www.mongodb.com/licensing/server-side-public-license>.
 */
import Reflux from 'reflux';
import URI from 'urijs';
import lodash from 'lodash';

import URLUtils from 'util/URLUtils';
import UserSodFactors from 'util/UserSodFactors';
import fetch from 'logic/rest/FetchProvider';
import CombinedProvider from 'injection/CombinedProvider';

const { SodFactorsActions } = CombinedProvider.get('SodFactors');

const SodFactorsStore = Reflux.createStore({
  listenables: [SodFactorsActions],
  sourceUrl: '/events/sodfactors',
  all: undefined,
  sodfactors: undefined,
  query: undefined,
  pagination: {
    count: undefined,
    page: undefined,
    pageSize: undefined,
    total: undefined,
    grandTotal: undefined,
  },

  getInitialState() {
    return this.getState();
  },

  propagateChanges() {
    this.trigger(this.getState());
  },

  getState() {
    return {
      all: this.all,
      sodfactors: this.sodfactors,
      query: this.query,
      pagination: this.pagination,
    };
  },

  sodfactorsUrl({ segments = [], query = {} }) {
    const uri = new URI(this.sourceUrl);
    const nextSegments = lodash.concat(uri.segment(), segments);

    uri.segmentCoded(nextSegments);
    uri.query(query);

    return URLUtils.qualifyUrl(uri.resource());
  },

  refresh() {
    if (this.all) {
      this.listAll();
    }

    if (this.pagination.page) {
      this.listPaginated({
        query: this.query,
        page: this.pagination.page,
        pageSize: this.pagination.pageSize,
      });
    }
  },

  listAll() {
    const promise = fetch('GET', this.sodfactorsUrl({ query: { per_page: 0 } }));

    promise.then((response) => {
      this.all = response.sodfactors;
      this.propagateChanges();

      return response;
    });

    SodFactorsActions.listAll.promise(promise);
  },

  listPaginated({ query = '', page = 1, pageSize = 10 }) {
    const promise = fetch('GET', this.sodfactorsUrl({
      query: {
        query: query,
        page: page,
        per_page: pageSize,
      },
    }));

    promise.then((response) => {
      this.sodfactors = response.sodfactors;
      this.query = response.query;

      this.pagination = {
        count: response.count,
        page: response.page,
        pageSize: response.per_page,
        total: response.total,
        grandTotal: response.grand_total,
      };

      this.propagateChanges();

      return response;
    });

    SodFactorsActions.listPaginated.promise(promise);
  },

  get(sodfactorsId) {
    const promise = fetch('GET', this.sodfactorsUrl({ segments: [sodfactorsId] }));

    promise.catch((error) => {
      if (error.status === 404) {
        UserSodFactors.error(`Unable to find SOD Configuration with id <${sodfactorsId}>, please ensure it wasn't deleted.`,
          'Could not retrieve SOD Configuration');
      }
    });

    SodFactorsActions.get.promise(promise);
  },

  create(sodfactors) {
    const promise = fetch('POST', this.sodfactorsUrl({}), sodfactors);

    promise.then(
      (response) => {
        UserSodFactors.success('SOD Factors created successfully', `SodFactors "${sodfactors.title}" was created successfully.`);
        this.refresh();

        return response;
      },
      (error) => {
        if (error.status !== 400 || !error.additional.body || !error.additional.body.failed) {
          UserSodFactors.error(`Creating SodFactors "${sodfactors.filename}" failed with status: ${error}`,
            'Could not save SodFactors');
        }
      },
    );

    SodFactorsActions.create.promise(promise);
  },

  update(sodfactorsId, sodfactors) {
    const promise = fetch('PUT', this.sodfactorsUrl({ segments: [sodfactorsId] }), sodfactors);

    promise.then(
      (response) => {
        UserSodFactors.success('SodFactors updated successfully', `SodFactors "${sodfactors.filename}" was updated successfully.`);
        this.refresh();

        return response;
      },
      (error) => {
        if (error.status !== 400 || !error.additional.body || !error.additional.body.failed) {
          UserSodFactors.error(`Updating SodFactors "${sodfactors.filename}" failed with status: ${error}`,
            'Could not update SodFactors');
        }
      },
    );

    SodFactorsActions.update.promise(promise);
  },

  delete(sodfactors) {
    const promise = fetch('DELETE', this.sodfactorsUrl({ segments: [sodfactors.id] }));

    promise.then(
      () => {
        UserSodFactors.success('SodFactors deleted successfully', `SodFactors "${sodfactors.filename}" was deleted successfully.`);
        this.refresh();
      },
      (error) => {
        UserSodFactors.error(`Deleting SodFactors "${sodfactors.filename}" failed with status: ${error}`,
          'Could not delete SodFactors');
      },
    );

    SodFactorsActions.delete.promise(promise);
  },

  test(sodfactors) {
    const promise = fetch('POST', this.sodfactorsUrl({ segments: ['test'] }), sodfactors);

    SodFactorsActions.test.promise(promise);
  },

  testPersisted(sodfactors) {
    const promise = fetch('POST', this.sodfactorsUrl({ segments: [sodfactors.id, 'test'] }));

    SodFactorsActions.testPersisted.promise(promise);
  },
});

export default SodFactorsStore;
